import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatRoute } from 'react-router-named-routes';

import ROUTES from 'react/routes';

import Link from 'react/generic/link/Link';
import Button from 'react/generic/button/Button';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import { PRODUCT_FORM_STEPS } from 'react/business/products/form/steps/form-steps.constants';

import messages from './form-choose-step.messages';
import classNames from './form-choose-step.module.scss';

const productFormHref = formatRoute(
  ROUTES.PRODUCT.CREATE_FORM.FORM,
  { step: PRODUCT_FORM_STEPS.PRESENTATION },
);

const initiativeFormHref = `${productFormHref}?isInitiative=true`;

const FormChooseStep = () => (
  <div className={classNames.container}>
    <div className={classNames.title}>
      <FormattedMessage {...messages.TITLE} />
    </div>
    <div className={classNames.descContainer}>
      <div className={classNames.desc}>
        <div className={classNames.icon}>
          <Asset name={ICON_MAP.idea} />
        </div>
        <div className={classNames.name}>
          <FormattedMessage {...messages.INITIATIVE} />
        </div>
        <div className={classNames.text}>
          <FormattedMessage {...messages.INIATIVE_DESC} />
        </div>
        <div className={classNames.list}>
          <ul>
            <li><FormattedMessage {...messages.INIATIVE_DESC_LIST_1} /></li>
            <li><FormattedMessage {...messages.INIATIVE_DESC_LIST_2} /></li>
            <li><FormattedMessage {...messages.INIATIVE_DESC_LIST_3} /></li>
            <li><FormattedMessage {...messages.INIATIVE_DESC_LIST_4} /></li>
          </ul>
        </div>
        <div className={classNames.buttonContainer}>
          <Link to={initiativeFormHref}>
            <Button className={classNames.button}>
              <FormattedMessage {...messages.INITIATIVE_CREATE} />
            </Button>
          </Link>
        </div>
      </div>
      <div className={classNames.desc}>
        <div className={classNames.icon}>
          <Asset name={ICON_MAP.cloud_upload_arrow} />
        </div>
        <div className={classNames.name}>
          <FormattedMessage {...messages.PRODUCT} />
        </div>
        <div className={classNames.text}>
          <FormattedMessage {...messages.PRODUCT_DESC} />
        </div>
        <div className={classNames.list}>
          <ul>
            <li><FormattedMessage {...messages.PRODUCT_DESC_LIST_1} /></li>
            <li><FormattedMessage {...messages.PRODUCT_DESC_LIST_2} /></li>
            <li><FormattedMessage {...messages.PRODUCT_DESC_LIST_3} /></li>
            <li><FormattedMessage {...messages.PRODUCT_DESC_LIST_4} /></li>
            <li><FormattedMessage {...messages.PRODUCT_DESC_LIST_5} /></li>
            <li><FormattedMessage {...messages.PRODUCT_DESC_LIST_6} /></li>
          </ul>
        </div>
        <div className={classNames.buttonContainer}>
          <Link to={productFormHref}>
            <Button className={classNames.button}>
              <FormattedMessage {...messages.PRODUCT_CREATE} />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default FormChooseStep;
